<template>
<section class="mt-20 flex justify-center">
    <div class="2xl:w-1/3 md:w-1/2 w-4/5 leading-loose">
        <h1 id="top">Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Andreae Technologies UG (haftungsbeschr&auml;nkt)<br />
        Spreestr. 4<br />
        12439 Berlin</p>

        <p>Handelsregister: HRB 230468 B<br />
        Registergericht: AG Charlottenburg</p>

        <p><strong>Vertreten durch:</strong><br />
        Mauritz Andreae</p>

        <h2>Kontakt</h2>
        <p>Telefon: 030-555742080<br />
        Telefax: 030-555742089<br />
        E-Mail: hi@poselytics.com</p>

        <h2>Redaktionell verantwortlich</h2>
        <p>Mauritz Andreae</p>

        <h2>EU-Streitschlichtung</h2>
        <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank"
            rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.
            <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">
            https://www.e-recht24.de/impressum-generator.html</a></p>
    </div>
</section>
</template>

<script>

export default {
  name: 'Imprint',
  components: {
  },
};
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1 {
        @apply text-3xl text-primary-dark font-bold py-3;
        }
    h2 {
        @apply text-xl text-primary-dark font-bold py-2;
        }
}
</style>
